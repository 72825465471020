<template>
	<div class="app-container">
		<el-card>
			<div class="info">
				<span>时间：</span>
				<div class="block">
					<el-date-picker v-model="searchDate" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
						@change="pickchange">
					</el-date-picker>
				</div>
				<div>
					<el-button type="primary" @click="goSearch">查询</el-button>
				</div>
			</div>

			<div class="flex-row">
				<!-- 云图 -->
				<div class="wordcloud" id="wordcloud" v-show="ifShow_wordcloud"></div>
				<div class="wordcloud" id="wordcloud_none" v-show="ifShow_wordcloud == false">
					<div style="text-align:center;font-size:15px;color:gray;padding-top:150px;">暂无云词数据</div>
				</div>

				<!-- 表格 -->
				<el-table id="vcfResult" class="wordcloudRight40" ref="multipleTable" :data="systemList"
					tooltip-effect="dark" style="width:100%" element-loading-text="Loading" border fit
					highlight-current-row>
					<!-- <el-table-column prop="id" align="center" label="序号" width="60px"></el-table-column> -->
					<el-table-column type="index" width="50px" align="center" label="序号"></el-table-column>
					<el-table-column prop="name" align="left" label="院系"></el-table-column>
					<el-table-column prop="totalBonus" align="center" label="当月总加分" width="120px"></el-table-column>
					<el-table-column prop="totalDeduction" align="center" label="当月总扣分" width="120px"></el-table-column>
					<el-table-column prop="depscore" align="center" label="最终得分" width="120px"></el-table-column>
				</el-table>
			</div>
			<!-- 按钮 （日、周、月） -->
			<div class="mgt24 align-center">
				<el-button-group>
					<el-button v-for="(item, index) in tab" :key="index" :class="index == current ? 'active' : ''"
						@click="tabs(index)">{{ item }}</el-button>
				</el-button-group>
				<el-button type="primary" style="float: right;" @click="exportExcel">数据导出</el-button>
			</div>

			<!-- 折线走势图 -->
			<div class="test2">
				<div v-show="ifShow_line_chart" id="myChart2"></div>
				<div v-show="ifShow_line_chart == false"
					style="text-align:center;font-size:15px;color:gray;padding-top:150px;">暂无折线图数据</div>
			</div>

			<!-- 饼图 -->
			<div class="echarts-list">
				<div class="echarts-pieChart" v-for="(item, index) in piedata" :key="index">
					<pieEcharts v-if="flag2" :piedata="piedata" :dataPie="dataPie" :index="index" />
				</div>
			</div>

			<el-dialog v-if="dialogFormVisible" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible"
				:before-close="handleClose" width="50%" minWidth="600px">
				<addComponent ref="dialogForm" @changeDialogFormVisible="changeDialogFormVisible($event)"
					:dialog-status="dialogStatus" :dialogStatus="dialogStatus" />
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
// import { getList, getBrigade, getRole, putRole } from "../../api/users.js";
import * as api from "@/api/users";
import * as echarts from "echarts";
import addComponent from "./addComponent.vue";
// 将图组建引入
// import lineEcharts from '../echartsComponent/line_echarts.vue'
import pieEcharts from "../echartsComponent/pie_echarts.vue";
export default {
	components: {
		addComponent,
		//lineEcharts,
		pieEcharts,
	},
	name: "test2",
	data() {
		return {
			flag: false,
			flag2: false,
			dataDart: [],
			value1: "",
			month: {},
			threeMonth: {},
			oneYear: {},
			current: 0,
			tab: ["日", "周", "月"],
			systemList: [],
			form: {},
			searchDate: [], //默认时间
			idis: "",
			formLabelWidth: "120px",
			Authorization: "",
			dialogFormVisible: false,
			dialogStatus: "",
			textMap: {
				update: "编辑",
				create: "新增",
				view: "详情",
			},

			listQuest: {
				page: 1,
				pageSize: 10,
			},
			roleList: [],
			xDataList: [],
			yDataList: [],
			options: [{
				label: "有",
				value: 1,
			},
			{
				label: "没有",
				value: 0,
			},
			],
			roles: [],
			tableData: [],
			input: "",
			// depid: "",
			namelist: [],
			dataline: [],
			xdatelist: [],
			pie: "",
			dataPie: [],
			dparmetID: "",
			did: "",
			// 饼图数据
			piedata: {},

			// --------- 云词 --------- 
			// 云词
			wordcloudList: [],
			// 是否显示 云词
			ifShow_wordcloud: true,
			// 云词 obj
			wordcloud_chart: null,

			// --------- 折线图 ---------
			// 折线图数据
			linedata: {},
			// 空对象
			line_chart: null,
			// 是否显示 折线图
			ifShow_line_chart: true,

			// 调试
			classPieData: [], // 班级指标饼图
			studentPieData: [], // 学生指标饼图
		};
	},
	computed: {
		// 折线图
		getoption() {
			let that = this;
			let option = {
				title: {
					text: "", // 主标题
					subtext: "", // 副标题
					x: "left", // x轴方向对齐方式
				},
				tooltip: {
					trigger: "axis", // axis   item   none三个值
				},
				xAxis: {
					type: "category",
					data: that.xdatelist[0],
					name: "",
					axisLabel: {
						interval: 0,
						rotate: 38,
						formatter: function (value) {
							var ret = ""; //拼接加\n返回的类目项
							var maxLength = 11; //每项显示文字个数
							var valLength = value.length; //X轴类目项的文字个数
							var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
							if (rowN > 1) {
								//如果类目项的文字大于5,
								for (var i = 0; i < rowN; i++) {
									var temp = ""; //每次截取的字符串
									var start = i * maxLength; //开始截取的位置
									var end = start + maxLength; //结束截取的位置
									//这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
									temp = value.substring(start, end) + "\n";
									ret += temp; //凭借最终的字符串
								}
								return ret;
							} else {
								return value;
							}
						},
					},
				},
				yAxis: {
					type: "value",
					name: "",
				},
				legend: {
					orient: "horizontal",
					x: "center",
					y: "top",
					top: 20,
					data: that.namelist,
				},
				series: that.dataline,
			};
			return option;
		},
	},
	created() {
		this.loadData();
		// 院系列表
		this.getSystemList();
		// (每日)折线图
		this.getDataday();
		// 饼图数据
		// this.getPieAll();
		this.getNewPieAll();
	},
	mounted() {
		// 加载数据 并 绘制 词云
		this.loadWordCloud({});
		// 绘制 折线图
		this.drawLine1();
	},
	methods: {
		// 词云
		loadWordCloud(params) {
			api.groupByAll(params).then((res) => {
				this.wordcloudList = res.data;
				this.showWordcloud();
			});
		},
		showWordcloud() {
			// 基于准备好的dom，初始化echarts实例
			var objTemp = document.getElementById("wordcloud");
			// 先销毁
			// if (this.wordcloud_chart != null && this.wordcloud_chart != "" && this.wordcloud_chart != undefined) {
			//   this.wordcloud_chart.dispose(); // 销毁
			//   this.wordcloud_chart = null;
			// }
			// 再创建
			if (this.wordcloud_chart == null) {
				this.wordcloud_chart = echarts.init(objTemp);
			}
			if (this.wordcloudList != null && this.wordcloudList.length > 0) {
				this.ifShow_wordcloud = true;
				// 绘制图表
				this.wordcloud_chart.setOption({
					backgroundColor: "#ffffff",
					tooltip: {
						show: true,
					},
					series: [{
						name: "热点分析", //数据提示窗标题
						type: "wordCloud",
						drawOutOfBound: true,
						sizeRange: [10, 38], //画布范围，如果设置太大会出现少词（溢出屏幕）
						rotationRange: [-5, 20], //数据翻转范围
						rotationRange1: [-10, 20], // 数据翻转范围
						shape: "circle", //形状
						textPadding: 0,
						autoSize: {
							enable: true,
							minSize: 6,
						},
						textStyle: {
							color: () => {
								return (
									"rgb(" + [
										Math.round(Math.random() * 255),
										Math.round(Math.random() * 255),
										Math.round(Math.random() * 255),
									].join(",") +
									")"
								);
							},
							emphasis: {
								shadowBlur: 10,
								shadowColor: "#333",
							},
						},
						data: this.wordcloudList,
					},],
				})
			} else {
				this.ifShow_wordcloud = false;
			}
		},

		async getNewPieAll() {
			let params = {
				startTime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this
					.searchDate[0]).format("YYYY-MM-DD") : "",
				endTime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this.searchDate[
					1]).format("YYYY-MM-DD") : "",
			};

			let mPieData = await api.getWholeSchoolDstaList(params);
			let datas = mPieData.data;
			// 新数组
			let arr = [];
			for (let i = 0; i < datas.length; i++) {
				let pie = (datas[i].type = "pie");
				let dataList = (datas[i].data = datas[i].dataList);
				let cname = "";
				if (typeof (datas[i].name) != "undefined") cname = datas[i].name;
				if (typeof (datas[i].cname) != "undefined") cname = datas[i].cname;
				if (typeof (cname) == "undefined") cname = "";
				let obj = {
					type: pie,
					name: cname,
					data: dataList,
				};
				arr.push(obj);
			}

			// 饼图数据
			let piedatas = [];
			arr.forEach((item) => {
				if (item.data.length <= 0) {
					piedatas.push(item);
				} else {
					piedatas.push(item.data);
				}
			});

			let piearr = [];
			for (let i = 0; i < piedatas.length; i++) {
				let dataPie = piedatas[i].map((item) => {
					return {
						// 解决 0 NAN%
						value: Math.abs(item.score == 0 ? 1 : item.score),
						name: item.category + "," + (item.score == 0 ? 1 : item.score),
					};
				});
				if (dataPie.length == 1 && dataPie[0].name == ',1' && 1 == dataPie[0].value) {
					piearr.push([]);
				} else {
					piearr.push(dataPie);
				}
			}
			this.dataPie = piearr;
			this.piedata = arr;
			this.flag2 = true;
		},

		// 所有饼图
		async getPieAll() {
			let params = {
				starttime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this
					.searchDate[0]).format("YYYY-MM-DD HH:mm:ss") : "",
				endtime: this.searchDate != null && this.searchDate.length > 0 ? this.$moment(this.searchDate[
					1]).format("YYYY-MM-DD HH:mm:ss") : "",
			};
			let classPieData = await api.piechartSchool(params); // 班级指标饼图
			let studentPieData = await api.studentChartAll(params); // 学生指标饼图
			this.classPieData = classPieData.data; // 班级指标饼图
			this.studentPieData = studentPieData.data; // 学生指标饼图

			// 合并：班级数据 + 学员数据
			let datas = this.mergeArr(classPieData.data, studentPieData.data);
			let arr = [];
			for (let i = 0; i < datas.length; i++) {

				let pie = (datas[i].type = "pie");
				let dataList = (datas[i].data = datas[i].dataList);

				// let dname = (datas[i].name = datas[i].dname);
				let dname = "";
				if (typeof (datas[i].name) != "undefined") dname = datas[i].name;
				if (typeof (datas[i].dname) != "undefined") dname = datas[i].dname;
				if (typeof (dname) == "undefined") dname = "";

				let obj = {
					type: pie,
					name: dname,
					data: dataList,
				};
				arr.push(obj);
			}
			// 系名
			let pienamelist = [];
			arr.forEach((item) => {
				pienamelist.push(item.name);
			});
			// 饼图数据
			let piedatas = [];
			arr.forEach((item) => {
				piedatas.push(item.data);
			});
			let piearr = [];
			for (let i = 0; i < piedatas.length; i++) {
				let dataPie = piedatas[i].map((item) => {
					return {
						// 解决 0 NAN%
						value: Math.abs(item.score == 0 ? 1 : item.score),
						name: item.category + "," + (item.score == 0 ? 1 : item.score),
					};
				});
				piearr.push(dataPie);
			}
			this.dataPie = piearr;
			this.piedata = arr;
			this.flag2 = true;
		},
		// 日
		getDataday() {
			let params = {
				starttime: this.searchDate != null && this.searchDate.length > 0 ?
					this.$moment(this.searchDate[0]).format("YYYY-MM-DD") : "",
				endtime: this.searchDate != null && this.searchDate.length > 0 ?
					this.$moment(this.searchDate[1]).format("YYYY-MM-DD") : "",
			};
			api.getdataday(params).then((res) => {
				let datas = res.data;
				let arr = [];
				for (let i = 0; i < datas.length; i++) {
					let line = (datas[i].type = "line");
					let dataList = (datas[i].data = datas[i].dataList);
					let dname = (datas[i].name = datas[i].dname);
					let dateList = (datas[i].xdate = datas[i].dateList);
					let obj = {
						type: line,
						data: dataList,
						name: dname,
						xdate: dateList,
					};
					arr.push(obj);
				}
				let list = [];
				arr.forEach((item) => {
					list.push(item.name);
				});
				let xlist = [];
				arr.forEach((item) => {
					xlist.push(item.xdate);
				});
				this.dataline = arr;
				this.namelist = list;
				this.xdatelist = xlist;
				this.linedata = arr;
				this.flag = true;
				this.drawLine1();
			});
			this.getNewPieAll();
		},
		// 周
		getDataweek() {
			let params = {
				starttime: "",
				endtime: "",
			};
			api.getdataweek(params).then((res) => {
				let datas = res.data;
				let arr = [];
				for (let i = 0; i < datas.length; i++) {
					let line = (datas[i].type = "line");
					let dataList = (datas[i].data = datas[i].dataList);
					let dname = (datas[i].name = datas[i].dname);
					let dateList = (datas[i].xdate = datas[i].dateList);
					let obj = {
						type: line,
						data: dataList,
						name: dname,
						xdate: dateList,
					};
					arr.push(obj);
				}
				let list = [];
				arr.forEach((item) => {
					list.push(item.name);
				});
				let xlist = [];
				arr.forEach((item) => {
					xlist.push(item.xdate);
				});
				this.dataline = arr;
				this.namelist = list;
				this.xdatelist = xlist;
				this.drawLine1();
			});
			this.getNewPieAll();
		},
		// 月
		getDatamonth() {
			let params = {
				starttime: "",
				endtime: "",
			};
			api.getdatamonth(params).then((res) => {
				let datas = res.data;
				let arr = [];
				for (let i = 0; i < datas.length; i++) {
					let line = (datas[i].type = "line");
					let dataList = (datas[i].data = datas[i].dataList);
					let dname = (datas[i].name = datas[i].dname);
					let dateList = (datas[i].xdate = datas[i].dateList);
					let obj = {
						type: line,
						data: dataList,
						name: dname,
						xdate: dateList,
					};
					arr.push(obj);
				}
				let list = [];
				arr.forEach((item) => {
					list.push(item.name);
				});
				let xlist = [];
				arr.forEach((item) => {
					xlist.push(item.xdate);
				});
				this.dataline = arr;
				this.namelist = list;
				this.xdatelist = xlist;
				this.drawLine1();
			});
			this.getNewPieAll();
		},
		// 选择时间触发
		pickchange(e) {
			this.searchDate = e;
		},
		// 算分接口
		addScore() {
			// 有bug cgj 220224
			// let starttime = this.$moment(this.searchDate[0]).format("YYYY-MM-DD");
			// let endtime = this.$moment(this.searchDate[1]).format("YYYY-MM-DD");
			let starttime = this.searchDate.length ? this.$moment(this.searchDate[0]).format("YYYY-MM-DD") : "";
			let endtime = this.searchDate.length ? this.$moment(this.searchDate[1]).format("YYYY-MM-DD") : "";
			let params = {
				starttime: starttime,
				endtime: endtime,
				depid: this.depid,
			};
			api.addScore(params).then((res) => {
				console.log(res, "算分数接口");
			});
		},
		tabs(index) {
			this.current = index;
			if (this.current == 0) {
				this.getDataday();
			} else if (this.current == 1) {
				this.getDataweek();
			} else {
				this.getDatamonth();
			}
		},
		// 院系得分 默认本月
		getSystemList() {
			let params = {
				page: 1,
				pageSize: 10,
			};
			api.getSystemList(params).then((res) => {
				this.systemList = res.data.records;
				this.dataDart = res.data.records;
				this.did = this.dataDart[0].id;
				// this.schoolPie(this.did)
			});
		},
		loadData() {
			this.getType();
		},

		// 折线图
		drawLine1() {
			// 基于准备好的dom，初始化echarts实例
			var objTemp = document.getElementById("myChart2");
			// 先销毁
			// if (this.line_chart != null && this.line_chart != "" && this.line_chart != undefined) {
			//   this.line_chart.dispose(); // 销毁
			//   this.line_chart = null;
			// }
			// 再创建
			if (this.line_chart == null) {
				this.line_chart = echarts.init(objTemp);
			}
			if (this.namelist != null && this.namelist.length > 0) {
				this.ifShow_line_chart = true;
				// 绘制图表
				this.line_chart.setOption(this.getoption);
			} else {
				this.ifShow_line_chart = false;
			}
		},

		drawline2() {
			// 基于准备好的dom，初始化echarts实例
			var objTemp = document.getElementById("myChart3");
			// 先销毁
			// if (this.pie != null && this.pie != "" && this.pie != undefined) {
			//   this.pie.dispose(); // 销毁
			//   this.pie = null;
			// }
			// 再创建
			if (this.line_chart == null) {
				this.pie = echarts.init(objTemp);
			}
			// 绘制图表
			this.pie.setOption(this.getoption1);
		},
		addInfo() {
			this.dialogStatus = "create";
			this.dialogFormVisible = true;
		},
		handleDelete(value) {
			console.log(value);
		},
		handleView(row) {
			this.dialogStatus = "view";
			this.dialogFormVisible = true;
			this.form = row;
			this.$nextTick(() => {
				this.$refs.dialogForm.showData(this.form);
			});
		},
		indexMethod(index) {
			return index * 1;
		},
		changeDialogFormVisible(data) {
			this.dialogFormVisible = data;
			api.getUserList({
				page: 1,
				pageSize: 10,
				classnum: "",
			})
				.then((res) => {
					if (res.status == 1) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
		},
		select(e) {
			console.log(e);
		},
		getType() {
			api.getCurrUserItem().then((res) => {
				this.idis = res.data.rname;
			});
		},
		handleClose(done) {
			this.$confirm("确认关闭？")
				.then(() => {
					done();
				})
				.catch(() => { });
		},
		handleSizeChange(val) {
			this.listQuest.pageSize = val;
			this.loadData();
		},
		handleCurrentChange(val) {
			this.listQuest.page = val;
			this.loadData();
		},
		handleEdit(row) {
			this.getRole();
			this.form.id = row.id;
			this.roles.roleName = row.role_name;
			this.dialogFormVisible = !this.dialogFormVisible;
		},
		// 查询
		goSearch() {
			this.dparmetID = this.form.did;

			// 有bug cgj 220224
			// let starttime = this.$moment(this.searchDate[0]).format("YYYY-MM-DD");
			// let endtime = this.$moment(this.searchDate[1]).format("YYYY-MM-DD");
			let starttime = this.searchDate.length ? this.$moment(this.searchDate[0]).format("YYYY-MM-DD") : "";
			let endtime = this.searchDate.length ? this.$moment(this.searchDate[1]).format("YYYY-MM-DD") : "";

			/* 有错误
			let promiseList = [];
			this.systemList.forEach((item) => {
			  let params = {
				starttime,
				endtime,
				depid: item.id,
			  };
			  promiseList.push(api.addScore(params));
			});
			let dataList = [];
			Promise.all(promiseList).then((resList) => {
			  dataList = resList.map(({ data }) => {
				let total = 0;
				data.forEach((item) => {
				  total += item.score;
				});
				return total / data.length;
			  });
			  for (let i = 0; i < this.systemList.length; i++) {
				this.systemList[i].depscore = dataList[i];
			  }
			});
			*/

			// 院系列表
			this.getSystemList();

			// 云词
			this.loadWordCloud({
				startTime: starttime,
				endTime: endtime
			});
			this.$nextTick(() => {
				this.getDataday();
				// this.getPieAll();
				this.getNewPieAll();
				this.showWordcloud();
			});
		},
		// 合并：班级数据 + 学员数据
		mergeArr(arr1, arr2) {
			// 新的合并方式
			let arr = [];
			for (let i = 0; i < arr1.length; i++) {
				arr.push(arr1[i]);
				if (typeof (arr1[i].dname) != "undefined") {
					for (let j = 0; j < arr2.length; j++) {
						// 院系名称 == 院系名称
						// alert(arr1[i].shortname + "," + arr2[j].shortname)

						// 同名称
						if (arr1[i].shortname == arr2[j].shortname) {
							arr.push(arr2[j]);
							break;
						}
					}
				}
			}

			/*
			// 有bug
			let arr = arr1.slice(0);
			arr2.forEach((item, index) => {
			  arr.splice(2 * (index + 1) - 1, 0, item);
			});
			*/

			return arr;
		},

		/**
		 * 导出Excel表格
		 */
		exportExcel() {
			let me = this;
			// 第一个引号里面是表格导出时的名字
			// 第二个是表格的id，这个插件是通过id导出的
			// me.outToExcel.exportExcel("全校数据.xlsx", "#vcfResult");
			api.exportSysData({ "mType": "all" }).then((res) => {
				if (res.status == 1) {
					window.open(res.data, '_blank');
				} else {
					me.$message.error(res.msg);
				}
			});
		},

	},
};
</script>
<style lang="scss" scoped>
.tabs {
	width: 200px;
	// background: red;
	margin: 20px auto 0;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	justify-items: center;
	text-align: center;
	cursor: pointer;
}

.tabs div {
	width: 40px;
	height: 40px;
}

.active {
	background: #409eff;
	color: #fff;
}

/deep/ .el-container {
	width: 100%;
	height: 100% !important;
}

.info {
	display: flex;

	span {
		line-height: 41px;
	}
}

.classbox {
	width: 100%;
	display: flex;
}

.test2 {
	width: 100%;
	height: 400px;
}

.test2 div {
	width: 100%;
	height: 100%;
}

.echarts-list {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: left;

	.echarts-pieChart {
		width: 48%;
		height: 400px;
		border-top: 1px dashed #ddd;
		border-bottom: 1px dashed #ddd;

		&:nth-child(2n) {
			border-right: 1px dashed #ddd;
		}

		&:nth-child(2n + 1) {
			border-left: 1px dashed #ddd;
		}
	}
}
</style>
